import { SORT } from 'utils.js';
import { SHOP_ID_MARKER } from 'constants.js';
import { makeEndpointHelpers, transformResponseArrayOfObjWithB64Imgs } from './utils.js';

export default builder => {
    const { GET, DELETE, POST, PATCH, PUT } = makeEndpointHelpers(builder);

    return ({
        // Auth
        getLoginURL: GET('auth-telia/request/'),
        login: POST('auth/', {
            body: ({ userType, ...body }) => body,
            onBefore: ({ userType }) => !userType && throw new Error('Missing `userType` from a `login` -call'),
        }),
        logout: POST('auth/logout/', {
            body: () => ({
                access_token: localStorage.access_token,
                refresh_token: localStorage.refresh_token,
                public_key: localStorage.public_key,
            }),
        }),
        refresh: POST('auth/refresh/', { body: () => ({ refresh_token: localStorage.refresh_token }) }),
        returnResponse: POST('auth-telia/response/'),

        // Location
        coordinates: POST('location/locations'),
        address: POST('location/address'),

        // Vehicle
        site: POST('owner/shop/search/'),
        siteSingle: GET(idSite => `owner/shop/${idSite}/`),
        single: PATCH(({ idCar }) => `owner/vehicle/${idCar}/`, { body: ({ idCar, ...body }) => body }),
        vinSearch: POST('owner/vehicle/search/'),
        history: GET('owner/vehicle/history/'),
        cert: GET(idCar => `owner/vehicle/${idCar}/certificate/`),
        getTraficomDataAsOperator: POST(`shop/${SHOP_ID_MARKER}/vehicle/detail/`),
        getTraficomCarKinds: GET(`shop/${SHOP_ID_MARKER}/traficom/kind/list`),

        // Shop operator user
        singleShopsUser: POST(`shop/operator/user/`),
        userList: GET(`shop/operator/user/list`),
        userShopList: GET(`shop/operator/user/shop/list`),
        deleteUser: DELETE(idUser => `shop/operator/user/${idUser}/`),
        updateUser: PATCH(
            ({ idUser }) => `shop/operator/user/${idUser}/`,
            { body: ({ idUser, ...body }) => body },
        ),
        sendShopUserPasswordReset: POST(idUser => `shop/operator/user/${idUser}/password-send-reset-url/`),

        // Shop operator shop
        shop: POST(`shop/operator/shop/`),
        shopList: GET(`shop/operator/shop/list/`, { transformResponse: res => res.sort(SORT.id) }),
        shopUserList: GET(`shop/operator/shop/user/list`),
        deleteShop: DELETE(idShop => `shop/operator/shop/${idShop}`),
        updateShop: PATCH(
            ({ idShop }) => `shop/operator/shop/${idShop}/`,
            { body: ({ idShop, ...body }) => body },
        ),

        // Shop
        user: GET('shop/user/'),
        editUser: PATCH('shop/user/'),
        resetPassword: POST('shop/user/password/send-reset-url/'),
        listUserShops: GET('shop/list/'),
        listExternal: GET(`shop/${SHOP_ID_MARKER}/list-external/`),
        setPassword: PUT(
            ({ token }) => `shop/user/password/reset/${token}/`,
            { body: ({ token, ...body }) => body },
        ),
        vehicle: POST(`shop/${SHOP_ID_MARKER}/vehicle/search/`),
        recycleInfo: GET(idCar => `shop/${SHOP_ID_MARKER}/vehicle/${idCar}/recycle/`),
        recycleOwnerInfo: POST(
            ({ idCar }) => `shop/${SHOP_ID_MARKER}/vehicle/${idCar}/owner/`,
            { body: ({ idCar, ...body }) => body },
        ),
        uploadRecyclingCert: POST(
            ({ idCar }) => `shop/${SHOP_ID_MARKER}/vehicle/${idCar}/certificate/`,
            { headers: { 'Content-Type': 'multipart/form-data' }, body: ({ body }) => body },
        ),
        createCarAndOwner: POST(`shop/${SHOP_ID_MARKER}/vehicle-owner/`),
        singleCar: GET(({ idCar }) => `shop/${SHOP_ID_MARKER}/vehicle/${idCar}/`),
        car: PATCH(
            ({ idCar }) => `shop/${SHOP_ID_MARKER}/vehicle/${idCar}/`,
            { body: ({ idCar, ...body }) => body },
        ),
        carWithFiles: GET(({ idCar }) => `shop/${SHOP_ID_MARKER}/vehicle-with-files/${idCar}/`),
        vehicleStatusProcess: GET(
            `shop/${SHOP_ID_MARKER}/vehicle/search-status/shop_process/`,
            { transformResponse: res => transformResponseArrayOfObjWithB64Imgs(res, ['picture_file_thumb']) },
        ),
        vehicleStatusIncoming: GET(
            `shop/${SHOP_ID_MARKER}/vehicle/search-status/shop_transit/`,
            { transformResponse: res => transformResponseArrayOfObjWithB64Imgs(res, ['picture_file_thumb']) },
        ),
        vehicleStatusTransfer: GET(
            `shop/${SHOP_ID_MARKER}/vehicle/search-status/shop_transfer/`,
            { transformResponse: res => transformResponseArrayOfObjWithB64Imgs(res, ['picture_file_thumb']) },
        ),
        // UNUSED
        /* vehicleStatusMyAvailable: GET(
            `shop/${SHOP_ID_MARKER}/vehicle/search-status/shop_onsite/`,
            { transformResponse: res => transformResponseArrayOfObjWithB64Imgs(res, ['picture_file_thumb']) },
        ), */
        vehicleStatusMyNewAvailable: GET(
            `shop/${SHOP_ID_MARKER}/vehicle/search-status/shop_listing/`,
            { transformResponse: res => transformResponseArrayOfObjWithB64Imgs(res, ['picture_file_thumb']) },
        ),
        vehicleStatusAvailable: GET(
            `shop/${SHOP_ID_MARKER}/vehicle/search-status-external/shop_listing/`,
            { transformResponse: res => transformResponseArrayOfObjWithB64Imgs(res, ['picture_file_thumb']) },
        ),
        carScore: GET(({ idCar }) => `shop/${SHOP_ID_MARKER}/vehicle/${idCar}/score/`),

        // Reports
        reportRecycledCarsPerYear: GET(`shop/${SHOP_ID_MARKER}/report/vehicle-recycled-per-year/`),
        reportRecycledCarsPerMonth: GET(`shop/${SHOP_ID_MARKER}/report/vehicle-recycled-per-month/`),
        reportRecycledCarsOfShopPerYear: GET(`shop/${SHOP_ID_MARKER}/report/shop-vehicle-recycled-per-year/`),
        reportRecycledCarsOfShopPerMonth: GET(`shop/${SHOP_ID_MARKER}/report/shop-vehicle-recycled-per-month/`),
    });
};
