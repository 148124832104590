import { createSlice } from '@reduxjs/toolkit';
import API from 'api/index.js';

const initialState = { users: [], shops: [] };

export const operatorUserManagementSlice = createSlice({
    name: 'operatorUserManagement',
    initialState,
    reducers: {},
    extraReducers: builder => {
        const {
            userList,
            shopList,
            singleShopsUser,
            updateUser,
            deleteUser,
            updateShop,
            deleteShop,
            shop,
        } = API.endpoints;

        builder
            .addMatcher(userList.matchFulfilled, (state, { payload }) => { state.users = payload; })
            .addMatcher(singleShopsUser.matchFulfilled, (state, { payload }) => { state.users.push(payload); })
            .addMatcher(updateUser.matchFulfilled, (state, { payload }) => {
                const i = state.users.findIndex(user => user.id === payload.id);
                if(i !== -1) state.users[i] = payload;
            })
            .addMatcher(deleteUser.matchFulfilled, (state, { meta: { arg: { originalArgs } } }) => {
                const i = state.users.findIndex(user => user.id === originalArgs);
                if(i !== -1) state.users.splice(i, 1);
            })
            .addMatcher(shopList.matchFulfilled, (state, { payload }) => { state.shops = payload; })
            .addMatcher(shop.matchFulfilled, (state, { payload }) => { state.shops.push(payload); })
            .addMatcher(updateShop.matchFulfilled, (state, { payload }) => {
                const i = state.shops.findIndex(shop => shop.id === payload.id);
                if(i !== -1) state.shops[i] = payload;
            })
            .addMatcher(deleteShop.matchFulfilled, (state, { meta: { arg: { originalArgs } } }) => {
                const i = state.shops.findIndex(shop => shop.id === originalArgs);
                if(i !== -1) state.shops.splice(i, 1);
            });
    },
});

export default operatorUserManagementSlice.reducer;
