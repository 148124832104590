import { Link } from 'react-router-dom';
import { t } from 'ttag';
import imgLogo from 'static/logo.png';
import { useListUserShopsQuery } from 'api/index.js';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector.js';
import './index.scss';

const NavItem = ({ to, label, hasShop, needsShop, isCurrent, className = '', ...rest }) => (
    <li className={`nav-item ${className}`} inert={(needsShop && !hasShop) ? '' : undefined} {...rest}>
        <Link to={to} aria-current={isCurrent ? 'page' : undefined} className='nav-link'>
            {label}
        </Link>
    </li>
);

const needsShop = true;

const OperatorNavigator = () => {
    const groups = useShallowEqualSelector(state => state.auth.groups);
    const shopName = useShallowEqualSelector(state => state.operatorHome.shopName);
    const shops = useShallowEqualSelector(state => state.operatorHome.shops);
    useListUserShopsQuery(undefined, { skip: shops !== null });

    const navItems = () => [
        // TRANS: Operator sidebar navigation labels
        { to: '/operator/recycle', label: t`ENTER_NEW_VEHICLE`, needsShop },
        { to: '/operator/incoming', label: t`INCOMING`, needsShop },
        { to: '/operator/available', label: t`AVAILABLE`, needsShop },
        { to: '/operator/process', label: t`IN_PROCESS`, needsShop },
        { to: '/operator/responsibility', label: t`(OPERATOR_NAVLINK)_RESPONSIBILITY`, needsShop },
        groups.includes('operator') && { to: '/operator/user-management', label: t`USER_MANAGEMENT`, needsShop },
        { to: '/operator/profile', label: t`PROFILE`, needsShop },
        { to: '/logout', label: t`LOGOUT` },
    ];

    return (
        <nav className='operator-navigator'>
            <Link to='/operator' className='nav-logo-wrap'>
                <img src={imgLogo} alt='DELV' />
            </Link>
            {shopName && (
                <div className='current-site'>
                    <span className='current-site-label'>{t`CURRENT_SITE`}: </span>
                    <span className='current-site-name'>{shopName}</span>
                </div>
            )}
            <menu className='nav-menu'>
                {navItems().map(item => item && (
                    <NavItem
                        key={item.to}
                        isCurrent={window.location.pathname.startsWith(item.to)}
                        hasShop={!!shopName}
                        {...item}
                    />
                ))}
            </menu>
        </nav>
    );
};

export default OperatorNavigator;
