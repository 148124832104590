import { createSlice } from '@reduxjs/toolkit';
import { getParsedLSItemOr } from 'utils.js';
import API from 'api/index.js';

export const operatorHomeSlice = createSlice({
    name: 'operatorHome',
    initialState: {
        shopId: getParsedLSItemOr('shopId', null, true),
        shopName: localStorage.shopName ?? null,
        shops: null, // `null` until received without errors, then [] or array of shops
    },
    reducers: {
        deselectShop: state => {
            localStorage.removeItem('shopId');
            localStorage.removeItem('shopName');
            state.shopId = null;
            state.shopName = null;
        },
        selectShop: (state, { payload }) => {
            const shopName = state.shops?.find(({ id }) => id === payload)?.name ?? null;

            state.shopId = payload;
            localStorage.shopId = payload;

            state.shopName = shopName;
            localStorage.shopName = payload;
        },
    },
    extraReducers: builder => {
        const { listUserShops } = API.endpoints;

        builder
            .addMatcher(listUserShops.matchFulfilled, (state, { payload }) => { state.shops = payload; });
    },
});

export const { deselectShop, selectShop } = operatorHomeSlice.actions;

export default operatorHomeSlice.reducer;
