import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ErrorMessages } from 'i18n/messages.js';
import { notify } from 'store/ducks/layout.js';
import { expiredTokenLogout } from 'store/ducks/auth.js';
import { SHOP_ID_MARKER, SERVER, FORCE_LOGOUT_CODES } from 'constants.js';
import { forceLogout } from 'utils.js';
import endpoints from './endpoints.js';

let isRefreshing = false;
const apiQueue = [];

const processQueue = error => {
    apiQueue.forEach(p => (error ? p.reject(error) : p.resolve()));
    apiQueue.splice(0, Infinity);
    isRefreshing = false;
};

const fetchBase = fetchBaseQuery({
    baseUrl: SERVER,
    prepareHeaders: headers => {
        const { access_token = null } = localStorage;
        access_token && headers.set('Authorization', `Bearer ${access_token}`);
        return headers;
    },
});

const baseQuery = async (args, api, extraOptions) => {
    const { getState, dispatch, endpoint } = api;
    const { url } = args;

    url.includes(SHOP_ID_MARKER) && (args.url = url.replace(SHOP_ID_MARKER, getState().operatorHome.shopId));

    if(isRefreshing) {
        return new Promise((resolve, reject) => apiQueue.push({ resolve, reject }))
            .then(() => fetchBase(args, api, extraOptions))
            .catch(err => Promise.reject(err));
    }

    let res = await fetchBase(args, api, extraOptions);
    const { error } = res;

    if(error) {
        const { status, data } = error;

        if(status === 'FETCH_ERROR') {
            console.error('API request failed with no response:', res);
            dispatch(notify({ text: ErrorMessages().NETWORK_ERROR, type: 'error' }));
            return res;
        }

        const { code } = data;
        const isLogout = endpoint === 'logout';

        // If user's refresh token expired, or there's some other problem with token
        if(!isLogout && FORCE_LOGOUT_CODES.includes(code)) {
            dispatch(expiredTokenLogout());
        } else if(!isLogout && code === 'TOKEN_EXPIRED' && status === 401) { // If the user needs new login token
            isRefreshing = true;

            const body = { refresh_token: localStorage.refresh_token };
            const tokenRes = await fetchBase({ url: 'auth/refresh/', method: 'POST', body }, api, extraOptions);
            const { error, data } = tokenRes;

            if(error) {
                processQueue(tokenRes);
                forceLogout();
                return tokenRes;
            } else {
                ['access_token', 'public_key', 'refresh_token'].forEach(k => localStorage.setItem(k, data[k]));
                processQueue();
                res = await fetchBase(args, api, extraOptions);
            }
        }
    }

    return res;
};

const API = createApi({ reducerPath: 'API', baseQuery, endpoints });

export const {
    // Auth
    useGetLoginURLQuery,
    useLoginMutation,
    useLogoutMutation,
    useReturnResponseMutation,
    // Location
    useCoordinatesMutation,
    useAddressMutation,
    // Vehicle
    useSiteMutation,
    useSiteSingleQuery,
    useSingleMutation,
    useVinSearchMutation,
    useHistoryQuery,
    useLazyCertQuery,
    useGetTraficomDataAsOperatorMutation,
    useLazyGetTraficomCarKindsQuery,
    // Shop
    useUserShopListQuery,
    useShopUserListQuery,
    useUserQuery,
    useEditUserMutation,
    useResetPasswordMutation,
    useListUserShopsQuery,
    useListExternalQuery,
    useShopMutation,
    useUpdateShopMutation,
    useDeleteShopMutation,
    useSetPasswordMutation,
    useShopListQuery,
    useSingleShopsUserMutation,
    useUserListQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useSendShopUserPasswordResetMutation,
    useVehicleMutation,
    useRecycleInfoQuery,
    useRecycleOwnerInfoMutation,
    useUploadRecyclingCertMutation,
    useCreateCarAndOwnerMutation,
    useSingleCarQuery,
    useCarMutation,
    useCarWithFilesQuery,
    useVehicleStatusProcessQuery,
    useLazyVehicleStatusProcessQuery,
    useVehicleStatusIncomingQuery,
    useVehicleStatusTransferQuery,
    // UNUSED
    // useVehicleStatusMyAvailableQuery,
    useVehicleStatusMyNewAvailableQuery,
    useVehicleStatusAvailableQuery,
    useCarScoreQuery,

    // Reports
    useReportRecycledCarsPerYearQuery,
    useReportRecycledCarsPerMonthQuery,
    useReportRecycledCarsOfShopPerYearQuery,
    useReportRecycledCarsOfShopPerMonthQuery,
} = API;

export const { resetApiState } = API.util;

export default API;
