import { useDispatch } from 'react-redux';
import { t } from 'ttag';
import { ReactComponent as IconAlert } from 'static/Common/icon-exclamation.svg';
import { ReactComponent as IconTick } from 'static/Common/icon-tick.svg';
import { ReactComponent as IconClose } from 'static/Common/icon-close.svg';
import { ReactComponent as IconBackend } from 'static/icon-backend.svg';
import { hideTopNotification } from 'store/ducks/layout.js';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector.js';
import './index.scss';

const HeaderNotificationIcon = type => ({
    success: <IconTick />,
    error: <IconAlert />,
    backendProblem: <IconBackend />,
})[type];

const TopNotification = () => {
    const dispatch = useDispatch();
    const { text, type = '', visible, style } = useShallowEqualSelector(state => state.layout.topNotification);

    return (
        <div className={`top-notification ${type} ${visible ? 'visible' : ''}`} style={style}>
            <div className='icon-text-wrapper'>
                {HeaderNotificationIcon(type) || null}
                <span className='notification-text'>{text}</span>
            </div>
            <button
                className='close-button'
                type='button'
                onClick={() => dispatch(hideTopNotification())}
            >
                <IconClose alt={t`CLOSE`} />
            </button>
        </div>
    );
};


export default TopNotification;
